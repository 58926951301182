import React from 'react';

const Lunch:React.FC = () => {


    return (
        <div>
            Lunch
        </div>
    );
};

export default Lunch;
