import {api} from "../../../index";


export const phoneGetPin = (phone: string) => {
    return api.post('/v1/auth/phonegetpin', {
        phone
    })
};

export const phoneLogin = (phone: string, pin: string) => {
    return api.post('/v1/auth/phonelogin', {
        phone,
        pin
    })

};

export const checkAuth = (phone: string, pin: string, token: string) => {
    const params = {
        phone,
        pin
    }

    const headers = {
        Authorization: `Bearer ${token}`
    }

    return api.get('/v1/auth/status', {
        params,
        headers
    })
};
