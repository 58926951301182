import {Auth} from "../../../types/Auth/types";
import {ERROR, LOGOUT_REQUEST, PHONE_LOGIN, REQUEST_PHONE_PIN, SUCCESS} from "../../constants/actionTypes";



const initialState: Auth.AuthState = {
    token: '',
    isPinRequested: false,
    data: null
}

export function authReducer (
    state = initialState,
    action: Auth.RequestPhonePin | Auth.RequestPhonePinResponse | Auth.PhoneLoginResponse
):Auth.AuthState {

    switch (action.type) {
        case `${REQUEST_PHONE_PIN}${SUCCESS}`:
            return {
                ...state,
                isPinRequested: true
            }

        case `${REQUEST_PHONE_PIN}${ERROR}`:
            return {
                ...state,
                isPinRequested: false
            }

        case `${PHONE_LOGIN}${SUCCESS}`:
            return {
                ...state,
                token: action.payload.token,
                data: action.payload.data
            }

        case LOGOUT_REQUEST:
            return {
                ...initialState
            };

        default:
            return state;
    }
}
