import axios from 'axios';
import {API_URL} from "../constants/env";



export const configureApi = () => {
    return axios.create({
        baseURL: API_URL,
        withCredentials: true
    });

}

