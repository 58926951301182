import React from "react";
import {Redirect, Route} from "react-router-dom";
import { connect } from "react-redux";



const PrivateRoute = ({ component, render, authData,  ...props}) => {

    const Component = render || component;

    const isAuthorized = () => {
        if(authData) {
            return !!authData.token
        }
        return false;
    };

    return (
        <Route
            {...props}
            render={props =>
                isAuthorized() ?
                    <Component {...props} />
                    :
                    <Redirect to={'/'}/>
            }
        />
    )
}


const mapStateToProps = (state) => ({
    authData: state.auth
});


export default connect(mapStateToProps, null)(PrivateRoute);
