import React, {useEffect} from 'react';
import { Row } from 'antd';
import { Layout } from 'antd';
import {connect} from "react-redux";
import {RootState} from "../../services/reducers";

import {checkAuth} from "../../services/actions/Auth/checkAuth";
import Sidebar from "./Menu";
import {logout} from "../../services/actions/Auth/logout";

interface T_Props {
    checkAuth: Function,
    logout: Function,
    auth: any,
}


const Lklayout: React.FC<T_Props> = ({checkAuth, auth, logout, children}) => {

    useEffect(() => {
        checkAuth();
    }, [auth.data, checkAuth]);

    return (
        <Row>
            <Sidebar logout={logout}/>
            <Layout style={{ padding: '0 24px 24px' }}>
                {children}
            </Layout>
        </Row>
    );
};

const mapStateToProps = ({auth}: RootState) => ({
    auth
})

const mapDispatchToProps = {
    checkAuth,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Lklayout);
