export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const ERROR = "_ERROR";
export const RESET = "_RESET";



export const REQUEST_PHONE_PIN = "REQUEST_PHONE_PIN";
export const PHONE_LOGIN = "PHONE_LOGIN";
export const CHECK_AUTH = "CHECK_AUTH";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";


