import React from 'react';

const Categories:React.FC = () => {


    return (
        <div>
            Categories
        </div>
    );
};

export default Categories;
