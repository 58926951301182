import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'antd';
import AuthForm from "../../components/AuthForm";
import {requestPhonePin} from "../../services/actions/Auth/requestPhonePin";
import {connect} from "react-redux";
import {RootState} from "../../services/reducers";
import {Auth} from "../../types/Auth/types";
import {phoneLogin} from "../../services/actions/Auth/phoneLogin";
import {MENU_STRUCTURE} from "../../services/constants/menuStructure";


const mapStateToProps = ({auth}: RootState) => ({
    auth
})

const mapDispatchToProps = {
    requestPhonePin,
    phoneLogin
}

const AuthPage = ({
                      requestPhonePin,
                      phoneLogin,
                      auth
                  }: Auth.PageProps) => {

    let history = useHistory();

    useEffect(() => {
        if(auth.token) {
            history.push(MENU_STRUCTURE.HOMEPAGE.path)
        }
    });

    const [formData, setFormData] = useState({
        phone: '',
        pin: ''
    })


    function requestAuth() {
        if(formData.phone && formData.pin) {
            phoneLogin(formData.phone, formData.pin);
        }
    }

    function requestPin() {
        if(formData.phone ) {
            requestPhonePin(formData.phone);
        }

    }

    return (
        <div className={'app-container'}>


            <Row className={'h100'} align={'middle'}>
                <Col span={12} offset={6}>
                    <AuthForm formData={formData} setFormData={setFormData} requestPin={requestPin} requestAuth={requestAuth} authData={auth}/>
                </Col>
            </Row>

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
