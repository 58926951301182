import React from 'react';
import {Route, Switch, BrowserRouter} from "react-router-dom";

import AuthPage from "../pages/Auth";
import Lklayout from "../templates/LKlayout";
import PrivateRoute from "../components/PrivateRoute/index";
import LkMain from "../pages/Lk/LkMain";
import {MENU_STRUCTURE} from "../services/constants/menuStructure";
import Categories from "../pages/Lk/Categories";
import Dishes from "../pages/Lk/Dishes";
import LunchCategories from "../pages/Lk/LunchCategories";
import Lunch from "../pages/Lk/Lunch";



const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path={"/"}
                    component={AuthPage}
                />


                <Route
                    path={MENU_STRUCTURE.HOMEPAGE.path}
                    render={() => (
                        <Lklayout>
                            <PrivateRoute
                                exact
                                path={MENU_STRUCTURE.HOMEPAGE.path}
                                component={LkMain}
                            />
                            <PrivateRoute
                                exact
                                path={MENU_STRUCTURE.CATEGORIES.path}
                                component={Categories}
                            />
                            <PrivateRoute
                                exact
                                path={MENU_STRUCTURE.DISHES.path}
                                component={Dishes}
                            />
                            <PrivateRoute
                                exact
                                path={MENU_STRUCTURE.LUNCH_CATEGORIES.path}
                                component={LunchCategories}
                            />
                            <PrivateRoute
                                exact
                                path={MENU_STRUCTURE.LUNCH.path}
                                component={Lunch}
                            />
                        </Lklayout>
                    )}
                />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
