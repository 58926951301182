import React from 'react';

const Dishes:React.FC = () => {


    return (
        <div>
            Dishes
        </div>
    );
};

export default Dishes;
