import {
    call,
    put,
    takeLatest,
    select
} from "redux-saga/effects";
import {CHECK_AUTH, PHONE_LOGIN, REQUEST_PHONE_PIN} from "../../constants/actionTypes";
import {checkAuth, phoneGetPin, phoneLogin} from "../../api/Auth";
import {Auth} from "../../../types/Auth/types";
import {requestPhonePinError, requestPhonePinSuccess} from "../../actions/Auth/requestPhonePin";
import {phoneLoginError, phoneLoginSuccess} from "../../actions/Auth/phoneLogin";
import {checkAuthError, checkAuthSuccess} from "../../actions/Auth/checkAuth";




export default function* authWatcher() {
    yield takeLatest(REQUEST_PHONE_PIN, phonePinWorker);
    yield takeLatest(PHONE_LOGIN, phoneLoginWorker);
    yield takeLatest(CHECK_AUTH, checkAuthWorker);
}

function* phonePinWorker({ payload: phone }: Auth.RequestPhonePin) {
    try {
        const response = yield call(phoneGetPin, phone);
        yield put(requestPhonePinSuccess(response.data));
    } catch(error) {
        yield put(requestPhonePinError(error));
    }
}

function* phoneLoginWorker({ payload: { phone, pin } }: Auth.PhoneLoginResponse) {
    try {
        const response = yield call(phoneLogin, phone, pin);
        yield put(phoneLoginSuccess(response.data));
    } catch(error) {
        yield put(phoneLoginError(error));
    }
}

function* checkAuthWorker({ payload: { phone, pin } }: Auth.PhoneLoginResponse) {
    const token = yield select(({auth}) => auth.token);
    try {
        const response = yield call(checkAuth, phone, pin, token);
        yield put(checkAuthSuccess(response.data));
    } catch(error) {
        yield put(checkAuthError(error));
    }
}

// function* logoutWorker({ payload: data }) {
//     try {
//         const response = yield call();
//         yield put();
//     } catch(error) {
//         yield put();
//     }
// }
