import React from 'react';
import { Form, Input, Button } from 'antd';
import {Auth} from "../../types/Auth/types";

type Props = {
    formData: any,
    setFormData: (e: any) => void,
    requestPin: () => void,
    requestAuth: () => void,
    authData: Auth.AuthState
}

const AuthForm = ({formData, setFormData, requestPin, requestAuth, authData}: Props) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    return (
        <Form
            layout="vertical"
        >
            <Form.Item label="Номер телефона">
                <Input placeholder="" value={formData.phone} name={'phone'}
                       onChange={onChange}/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={requestPin}>Запросить код</Button>
            </Form.Item>

            {authData.isPinRequested &&
                <>
                    <Form.Item label="Пин код">
                        <Input placeholder=""  value={formData.pin} name={'pin'} onChange={onChange}/>
                    </Form.Item>
                    <Button type="primary" onClick={requestAuth}>Авторизация</Button>
                </>
            }

        </Form>
    );
};

export default AuthForm;
