import {Auth} from "../../../types/Auth/types";
import {ERROR, CHECK_AUTH, SUCCESS} from "../../constants/actionTypes";


export function checkAuth(phone: string, pin: string): Auth.PhoneLoginResponse {
    return {
        type: CHECK_AUTH,
        payload: {phone, pin}
    }
}

export function checkAuthSuccess(data: any): Auth.PhoneLoginResponse {
    return {
        type: `${CHECK_AUTH}${SUCCESS}`,
        payload: data
    }
}

export function checkAuthError(error: any): Auth.PhoneLoginResponse {
    return {
        type: `${CHECK_AUTH}${ERROR}`,
        payload: error
    }
}

