
import {ERROR, REQUEST_PHONE_PIN, SUCCESS} from "../../constants/actionTypes";
import {Auth} from "../../../types/Auth/types";


export function requestPhonePin(phone: string): Auth.RequestPhonePin {
    return {
        type: REQUEST_PHONE_PIN,
        payload: phone
    }
}

export function requestPhonePinSuccess(data: any): Auth.RequestPhonePinResponse {
    return {
        type: `${REQUEST_PHONE_PIN}${SUCCESS}`,
        payload: data
    }
}

export function requestPhonePinError(error: any): Auth.RequestPhonePinResponse {
    return {
        type: `${REQUEST_PHONE_PIN}${ERROR}`,
        payload: error
    }
}

