import {Auth} from "../../../types/Auth/types";
import {ERROR, PHONE_LOGIN, SUCCESS} from "../../constants/actionTypes";


export function phoneLogin(phone: string, pin: string): Auth.PhoneLoginResponse {
    return {
        type: PHONE_LOGIN,
        payload: {phone, pin}
    }
}

export function phoneLoginSuccess(data: any): Auth.PhoneLoginResponse {
    return {
        type: `${PHONE_LOGIN}${SUCCESS}`,
        payload: data
    }
}

export function phoneLoginError(error: any): Auth.PhoneLoginResponse {
    return {
        type: `${PHONE_LOGIN}${ERROR}`,
        payload: error
    }
}

