import React from 'react';
import { Menu } from 'antd';
import {NavLink} from "react-router-dom";
import {MENU_STRUCTURE} from "../../../services/constants/menuStructure";



interface T_Props {
    logout: Function,
}

const Sidebar:React.FC<T_Props> = ({ logout }) => {


    return (
        <Menu
            selectedKeys={[window.location.pathname]}
            style={{ width: 256 }}
            mode="inline"
        >
            <Menu.Item key={MENU_STRUCTURE.HOMEPAGE.path}>
                <NavLink to={MENU_STRUCTURE.HOMEPAGE.path}>
                    Рестораны
                </NavLink>
            </Menu.Item>
            <Menu.ItemGroup title="Меню">
                <Menu.Item key={MENU_STRUCTURE.CATEGORIES.path}>
                    <NavLink to={MENU_STRUCTURE.CATEGORIES.path}>
                        Категории
                    </NavLink>
                </Menu.Item>
                <Menu.Item key={MENU_STRUCTURE.DISHES.path}>
                    <NavLink to={MENU_STRUCTURE.DISHES.path}>
                        Блюда
                    </NavLink>
                </Menu.Item>
                <Menu.Item key={MENU_STRUCTURE.LUNCH_CATEGORIES.path}>
                    <NavLink to={MENU_STRUCTURE.LUNCH_CATEGORIES.path}>
                        Категории Бизнес-ланч
                    </NavLink>
                </Menu.Item>
                <Menu.Item key={MENU_STRUCTURE.LUNCH.path}>
                    <NavLink to={MENU_STRUCTURE.LUNCH.path}>
                        Бизнес ланч
                    </NavLink>
                </Menu.Item>
                <Menu.Item onClick={() => logout()}>
                    Выход
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );
};

export default Sidebar;
