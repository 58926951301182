export const MENU_STRUCTURE = {
    HOMEPAGE: {
        path: '/lk'
    },
    CATEGORIES: {
        path: '/categories'
    },
    DISHES: {
        path: '/dishes'
    },
    LUNCH_CATEGORIES: {
        path: '/lunch_categories'
    },
    LUNCH: {
        path: '/lunch'
    }
}
