import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import './index.css';
import 'antd/dist/antd.css';
import './css/global.css';
import App from './App';

import rootSaga from "./services/sagas";
import {configureApi} from "./services/api";
import rootReducer from "./services/reducers";
import {loadFromLocalStorage, saveToLocalStorage} from "./services/helpers/localStorage";





const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    loadFromLocalStorage(),
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    )
);

store.subscribe(() => saveToLocalStorage(store.getState()));

sagaMiddleware.run(rootSaga);

export const api = configureApi();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
